<template>
  <rpm-layout>
<!--    <div class="flex justify-between items-center bg-white rounded-lg p-2 mb-2">-->
<!--      <div class="relative w-1/3">-->
<!--&lt;!&ndash;        <RpmSearch v-model="search"></RpmSearch>&ndash;&gt;-->
<!--      </div>-->
<!--      <div class="flex">-->
<!--&lt;!&ndash;        <button type="button" @click="$store.dispatch('getPatients')" class="py-2.5 px-5 text-sm font-medium text-gray-700 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200 ">Refresh</button>&ndash;&gt;-->
<!--      </div>-->
<!--    </div>-->
    <div class="bg-white rounded-lg p-2">
      <div class="flex justify-between items-center ">
        <el-select v-model="client" id="client" placeholder="Client" filterable>
          <el-option v-for="client in this.availableClients" :value="client.id" :key="client.id" :label="client.name"></el-option>
        </el-select>
        <el-select v-model="category">
          <el-option value="rpm" label="rpm"></el-option>
          <el-option value="ccm" label="ccm"></el-option>
        </el-select>
<!--        <select v-model="category" id="category" class="m-2 shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline">-->
<!--          <option value="rpm">2020</option>-->
<!--          <option value="ccm">2021</option>-->
<!--        </select>-->
        <el-select v-model="year" id="year" filterable>
          <el-option value="2020" label="2020"></el-option>
          <el-option value="2021" label="2021"></el-option>
          <el-option value="2022" label="2022"></el-option>
          <el-option value="2023" label="2023"></el-option>
          <el-option value="2024" label="2024"></el-option>
        </el-select>
        <el-select v-model="month" id="month" filterable>
          <el-option v-for="(name, key) in {1: 'January', 2: 'February', 3: 'March', 4: 'April', 5: 'May', 6: 'June', 7: 'July', 8: 'August', 9: 'September', 10: 'October', 11: 'November', 12: 'December'}" :value="key" :key="key" :label="name"></el-option>
        </el-select>
      </div>
<!--      <div class="bg-white rounded-lg p-2">-->
<!--        <div class="flex justify-between items-center ">-->
<!--          <div>-->
<!--            <label for="start-date">Billing Cycle ending from: </label>-->
<!--            <input class="m-2 shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" type="date" id="start-date" :value="startDate" />-->
<!--          </div>-->
<!--          <div>-->
<!--            <label for="end-date">Billing Cycle ending by: </label>-->
<!--            <input class="m-2 shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" type="date" id="end-date" :value="endDate" />-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
      <div class="flex justify-between items-center p-2">
        <div>
          <el-input placeholder="Search" v-model="search" />
        </div>
        <div>
          Total: ${{total}}
        </div>
        <div>
          <a target="_blank" :download="`${this.clientName}-${this.year}-${this.month}-billing-report.csv`" :href="csvLink()" class="m-2 py-2.5 px-5 text-sm font-medium text-gray-700 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200" v-if="tableDataFiltered.length">Download Report CSV</a>
        </div>
      </div>
      <el-table
          v-loading="loading"
          size="small"
          :fit="true"
          stripe
          :data="displayData"
          style="width: 100%"
          :default-sort="{prop: 'Patient Name', order: 'ascending'}"
          >
        <el-table-column sortable fixed min-width="120" prop="Patient Name" label="Patient Name">
          <template slot-scope="scope">
            <el-popover trigger="hover" placement="top">
              <p>Name: {{ scope.row['Patient Name'] }}</p>
              <el-tag class="cursor-pointer mt-2" @click="goToPatient(scope.row.id)">Details</el-tag>
              <div slot="reference" class="name-wrapper">
                <el-tag size="medium">{{ scope.row['First Name'] }}</el-tag>
              </div>
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column show-overflow-tooltip sortable min-width="110" prop="First Name" label="First Name"></el-table-column>
        <el-table-column show-overflow-tooltip sortable min-width="110" prop="Last Name" label="Last Name"></el-table-column>
        <el-table-column sortable min-width="120" prop="Middle Initial" label="Middle Initial"></el-table-column>
        <el-table-column show-overflow-tooltip sortable min-width="100" prop="Street 1" label="Street 1"></el-table-column>
        <el-table-column show-overflow-tooltip sortable min-width="100" prop="Street 2" label="Street 2"></el-table-column>
        <el-table-column show-overflow-tooltip sortable min-width="100" prop="City" label="City"></el-table-column>
        <el-table-column sortable min-width="100" prop="State" label="State"></el-table-column>
        <el-table-column sortable min-width="100" prop="Zip Code" label="Zip Code"></el-table-column>
        <el-table-column sortable min-width="110" prop="Phone 1" label="Phone 1"></el-table-column>
        <el-table-column sortable min-width="110" prop="Phone 3" label="Phone 3"></el-table-column>
        <el-table-column sortable min-width="120" :formatter="dateFormatter" prop="Date of Birth" label="Date of Birth"></el-table-column>
        <el-table-column sortable min-width="100" prop="Sex" label="Sex"></el-table-column>
        <el-table-column sortable min-width="120" prop="Soc Sec Num" label="Soc Sec Num"></el-table-column>
        <el-table-column sortable min-width="130" prop="Marital Status" label="Marital Status"></el-table-column>
        <el-table-column show-overflow-tooltip sortable min-width="120" prop="Plan Name" label="Plan Name"></el-table-column>
        <el-table-column show-overflow-tooltip sortable min-width="120" prop="Subscriber ID" label="Subscriber ID"></el-table-column>
        <el-table-column show-overflow-tooltip sortable min-width="100" prop="Group No" label="Group No"></el-table-column>
        <el-table-column show-overflow-tooltip sortable min-width="170" prop="Secondary Plan Name" label="Secondary Plan Name"></el-table-column>
        <el-table-column show-overflow-tooltip sortable min-width="190" prop="Secondary Subscriber ID" label="Secondary Subscriber ID"></el-table-column>
        <el-table-column show-overflow-tooltip sortable min-width="160" prop="Secondary Group No" label="Secondary Group No"></el-table-column>
        <el-table-column sortable min-width="170" prop="Insured Authorization" label="Insured Authorization"></el-table-column>
        <el-table-column sortable min-width="150" prop="Signature on File" label="Signature on File"></el-table-column>
        <el-table-column sortable min-width="100" :formatter="dateFormatter" prop="SOF Date" label="SOF Date"></el-table-column><!-- this sorting method is ok -->
        <el-table-column show-overflow-tooltip sortable min-width="100" prop="Email" label="Email"></el-table-column>
        <el-table-column show-overflow-tooltip sortable min-width="120" prop="Facility Name" label="Facility Name"></el-table-column>
        <el-table-column sortable min-width="120" :formatter="dateFormatter" prop="Billing Month" label="Billing Month"></el-table-column><!-- but this method doesn't require special sorting since the underlying data is ordered already -->
        <el-table-column show-overflow-tooltip sortable min-width="150" prop="Ordering Physician" label="Ordering Physician"></el-table-column>
        <el-table-column show-overflow-tooltip sortable min-width="150" prop="Health Coach" label="Health Coach"></el-table-column>
        <el-table-column show-overflow-tooltip sortable min-width="170" prop="Health Condition (DX)" label="Health Condition (DX)"></el-table-column>
        <el-table-column show-overflow-tooltip sortable min-width="170" prop="ICD Codes" label="ICD Codes"></el-table-column>

        <el-table-column show-overflow-tooltip sortable min-width="170" :formatter="dateFormatter" prop="Care Plan Start Date" label="Care Plan Start Date"></el-table-column>
        <el-table-column show-overflow-tooltip sortable min-width="170" prop="Days in Billing Cycle" label="Days in Billing Cycle"></el-table-column>
        <el-table-column show-overflow-tooltip sortable min-width="170" prop="Days From Start Date" label="Days From Start Date"></el-table-column>

        <el-table-column sortable min-width="150" prop="Total Review Time (minutes)" label="Total Review Time"></el-table-column>

        <el-table-column v-if="category === 'ccm'" sortable min-width="130" prop="Physician Time" label="Physician Time"></el-table-column>
        <el-table-column v-if="category === 'ccm'" sortable min-width="160" prop="Non-Physician Time" label="Non-Physician Time"></el-table-column>

        <el-table-column sortable min-width="120" prop="Locality Code" label="Locality Code"></el-table-column>

        <el-table-column v-if="category === 'ccm'" sortable min-width="120" prop="CCM CPT 99437 value" label="99437 value"></el-table-column>
        <el-table-column v-if="category === 'ccm'" sortable min-width="120" prop="CCM CPT 99439 value" label="99439 value"></el-table-column>
        <el-table-column v-if="category === 'ccm'" sortable min-width="120" prop="CCM CPT 99490 value" label="99490 value"></el-table-column>
        <el-table-column v-if="category === 'ccm'" sortable min-width="120" prop="CCM CPT 99491 value" label="99491 value"></el-table-column>

        <el-table-column v-if="category === 'rpm'" sortable min-width="120" prop="RPM CPT 99453 value" label="99453 value"></el-table-column>
        <el-table-column v-if="category === 'rpm'" sortable min-width="120" prop="RPM CPT 99454 value" label="99454 value"></el-table-column>
        <el-table-column v-if="category === 'rpm'" sortable min-width="120" prop="RPM CPT 99457 value" label="99457 value"></el-table-column>
        <el-table-column v-if="category === 'rpm'" sortable min-width="120" prop="RPM CPT 99458 value" label="99458 value"></el-table-column>

        <el-table-column sortable min-width="120" prop="Sub Total" label="Sub Total"></el-table-column>

        <el-table-column fixed="right" v-if="category === 'ccm'" sortable min-width="80" prop="CCM CPT 99491" label="99491"></el-table-column>
        <el-table-column fixed="right" v-if="category === 'ccm'" sortable min-width="80" prop="CCM CPT 99490" label="99490"></el-table-column>
        <el-table-column fixed="right" v-if="category === 'ccm'" sortable min-width="80" prop="CCM CPT 99439" label="99439"></el-table-column>
        <el-table-column fixed="right" v-if="category === 'ccm'" sortable min-width="80" prop="CCM CPT 99437" label="99437"></el-table-column>

        <el-table-column fixed="right" v-if="category === 'rpm'" sortable min-width="80" prop="RPM CPT 99458" label="99458"></el-table-column>
        <el-table-column fixed="right" v-if="category === 'rpm'" sortable min-width="80" prop="RPM CPT 99457" label="99457"></el-table-column>
        <el-table-column fixed="right" v-if="category === 'rpm'" sortable min-width="80" prop="RPM CPT 99454" label="99454"></el-table-column>
        <el-table-column fixed="right" v-if="category === 'rpm'" sortable min-width="80" prop="RPM CPT 99453" label="99453"></el-table-column>


        <el-table-column sortable :formatter="dateFormatter" min-width="110" prop="start" label="Billing Start"></el-table-column>
        <el-table-column sortable :formatter="dateFormatter" min-width="110" prop="end" label="Billing End"></el-table-column>
      </el-table>

      <el-divider></el-divider>

      <div style="text-align: center">
        <el-pagination
            background
            layout="prev, pager, next"
            @current-change="handleCurrentChange"
            :page-size="pageSize"
            :total="tableDataFiltered.length">
        </el-pagination>
      </div>
    </div>

  </rpm-layout>
</template>


<style>
body {
  margin: 0;
}
</style>

<script>
import RpmLayout from '../../layouts/RpmLayout.vue';
import moment from "moment-timezone";
import Vue from "vue";
import PatientMixin from "@/mixins/Patient";


export default {
  mixins: [PatientMixin],
  components: {RpmLayout},
  data() {
    return {
      search: '',
      client: null,
      category: 'rpm',
      year: '2023',
      month: '12',
      tableData: [],
      token: null,
      loading: true,

      page: 1,
      pageSize: 10
    };
  },
  created: async function () {
    this.token = await this.$auth.getTokenSilently();
    Promise.all([
      !this.$store.state.patients.authUser.id ? this.$store.dispatch('getAuthUser') : null,
    ]).finally(() => {
      this.client = this.availableClients[0].id;
      this.loading = false;
    });
  },
  mounted: function () {
    // this.setRange();
  },
  computed: {
    total() {
      return this.tableDataFiltered.reduce((a,r) => r['Sub Total'] + a, 0).toFixed(2);
      // return this.tableDataFiltered.length;
    },
    clientName() {
      if (!this.client) return '';
      return this.availableClients.find((c) => c.id == this.client).name;
    },
    tableDataFiltered() {
      if (!this.tableData || this.tableData.length === 0) return [];
      if (this.search === '') {
        return this.tableData;
      }
      return this.tableData.filter(r => Object.values(r).filter(val => String(val).toLowerCase().includes(this.search.toLowerCase())).length);
    },
    availableClients() {
      return this.$store.state.patients.authUser.available_clients;
    },
    displayData() {
      if (!this.tableDataFiltered || this.tableDataFiltered.length === 0) return [];
      return this.tableDataFiltered.slice(this.pageSize * this.page - this.pageSize, this.pageSize * this.page)
    },
    arrayToCSV() {
      if (!this.tableDataFiltered.length) {
        return '';
      }
      const escapeField = field => `"${(''+field).replace(/"/g, '""').replace(/\n/g, '\\n')}"`;

      const csvHeaders = Object.keys(this.tableDataFiltered[0]).map(escapeField).join(',');
      const csvRows = this.tableDataFiltered.map(row => Object.values(row).map(escapeField).join(',')).join('\n');

      return `${csvHeaders}\n${csvRows}`;
    },

  },
  watch: {
    year() {
      // this.setRange();
      this.getData();
    },
    month() {
      // this.setRange();
      this.getData();
    },
    category() {
      this.getData();
    },
    client() {
      this.getData();
    },
  },
  methods: {
    dateTimeFormatter(row, column, cellValue, index) {
      return cellValue ? moment.utc(cellValue).local().format("MMM D, YYYY h:mm a") : '';
    },
    dateFormatter(row, column, cellValue, index) {
      return cellValue ? moment(cellValue).format("MMM D, YYYY") : '';
    },
    dateSort(a, b) {
      return moment(a).valueOf() - moment(b).valueOf();
    },
    // setRange() {
    //   this.startDate = moment({year: this.year, month: this.month - 1}).endOf('month').format('YYYY-MM-DD');
    //   this.endDate = moment({year: this.year, month: this.month - 1}).endOf('month').add(15, 'days').format('YYYY-MM-DD');
    // },
    csvLink() {
      const file = new File([this.arrayToCSV], 'report.csv', {type: 'text/csv',});
      return URL.createObjectURL(file);
    },
    handleCurrentChange(val) {
      this.page = val;
    },
    async getData() {
      if (!(this.client && this.category)) {
        return;
      }
      this.loading = true;
      return Vue.$http
          .get("/api/rpm_ccm_dashboard/billing-data", {
            params: {
              token: await this.token,
              category: this.category,
              client: this.client,
              month: this.month,
              year: this.year,
            },
          })
          .then((res) => (this.tableData = res.data.data))
          .catch((error) => this.$awn.warning(error.response.exception))
          .finally(() => {
            this.loading = false;
          });
    },
  },
};
</script>
